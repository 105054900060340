/* Basscss Margin */

.m0  { margin:        0 }

.mt0 { margin-top:    0 }

.mr0 { margin-right:  0 }

.mb0 { margin-bottom: 0 }

.ml0 { margin-left:   0 }

.mx0 { margin-left:   0; margin-right:  0 }

.my0 { margin-top:    0; margin-bottom: 0 }

.m0-5  { margin:        0.5rem }

.mt0-5 { margin-top:    0.5rem }

.mr0-5 { margin-right:  0.5rem }

.mb0-5 { margin-bottom: 0.5rem }

.ml0-5 { margin-left:   0.5rem }

.mx0-5 { margin-left:   0.5rem; margin-right:  0.5rem }

.my0-5 { margin-top:    0.5rem; margin-bottom: 0.5rem }

.m1  { margin:        1rem }
.mr0 { margin-right:  0 }
.mt1 { margin-top:        1rem }

.mr1 { margin-right:        1rem }

.mb1 { margin-bottom:        1rem }

.ml1 { margin-left:        1rem }

.mx1 { margin-left:        1rem; margin-right:        1rem }

.my1 { margin-top:        1rem; margin-bottom:        1rem }

.m2  { margin:        2rem }

.mt2 { margin-top:        2rem }

.mr2 { margin-right:        2rem }

.mb2 { margin-bottom:        2rem }

.ml2 { margin-left:        2rem }

.mx2 { margin-left:        2rem; margin-right:        2rem }

.my2 { margin-top:        2rem; margin-bottom:        2rem }

.mt2-5 { margin-top: 2.5rem; }

.m3  { margin:        3rem }

.mt3 { margin-top:        3rem }

.mr3 { margin-right:        3rem }

.mb3 { margin-bottom:        3rem }

.ml3 { margin-left:        3rem }

.mx3 { margin-left:        3rem; margin-right:        3rem }

.my3 { margin-top:        3rem; margin-bottom:        3rem }

.m4  { margin:        4rem }

.mt4 { margin-top:        4rem }

.mr4 { margin-right:        4rem }

.mb4 { margin-bottom:        4rem }

.ml4 { margin-left:        4rem }

.mx4 { margin-left:        4rem; margin-right:        4rem }

.my4 { margin-top:        4rem; margin-bottom:        4rem }

.mxn1 { margin-left:        -0.5rem; margin-right:        -0.5rem; }

.mxn2 { margin-left:        -1rem; margin-right:        -1rem; }

.mxn3 { margin-left:        -2rem; margin-right:        -2rem; }

.mxn4 { margin-left:        -4rem; margin-right:        -4rem; }

.mt2-5 { margin-top: 2.5rem}

.my6-75 { margin-top: 6.75rem; margin-bottom: 6.75rem }

.mt6-75 { margin-top: 6.75rem}

.mb6-75 { margin-bottom: 6.75rem}

.mt7-5 { margin-top: 7.5rem}

.mb10 { margin-bottom: 10rem}

.mb12 { margin-bottom: 12rem}

.ml-17 { margin-left: 17%; }

.m-auto  { margin: auto; }

.mt-auto { margin-top: auto }

.mr-auto { margin-right: auto }

.mb-auto { margin-bottom: auto }

.ml-auto { margin-left: auto }

.mx-auto { margin-left: auto; margin-right: auto; }

.my-auto { margin-top: auto; margin-bottom: auto; }

@media(max-width:767px) {
  .mmt0 {
    margin-top: 0rem;
  }
  .mmb0 {
    margin-bottom: 0rem;
  }
  .mmt2 {
    margin-top: 2rem;
  }
  .mmt3 {
    margin-top: 3rem;
  }
  .mmt3-5 {
    margin-top: 3.5rem;
  }
  .mmb1 {
    margin-bottom: 1rem;
  }
  .mmb2 {
    margin-bottom: 2rem;
  }
  .mmb6 {
    margin-bottom: 6rem;
  }
  .mmb9 {
    margin-bottom: 9rem;
  }
  .mmb10 {
    margin-bottom: 10rem;
  }
}
