.grid {
  display: grid;
}

.align-base {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-items: center;
}

.justify-end {
  justify-self: end;
}

.grid-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-4 {
  grid-template-columns: 25% 25% 25% 25%;
}

@media (max-width: 1024px) {
  .grid-2 {
    grid-template-columns: 100%;
  }

  .grid-4 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .justify-end {
    justify-self: initial;
  }

  .grid-2 {
    grid-template-columns: 100%;
  }

  .grid-4 {
    grid-template-columns: 100%;
  }
}
