/* Basscss Typography */

.font-serif {
  font-family: Yrsa, serif;
}

.font-base {
  font-family: ManifoldCF, serif;
}

.text-decoration-none {
  text-decoration: none;
}

.bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.caps {
  text-transform: uppercase;
  letter-spacing: 0.6px;
}

.uppercase {
  text-transform: uppercase;
}

.left-align {
  text-align: left;
}

.center {
  text-align: center;
}

.right-align {
  text-align: right;
}

.justify {
  text-align: justify;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  word-wrap: break-word;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 1.125;
}

.line-height-3 {
  line-height: 1.25;
}

.line-height-4 {
  line-height: 1.5;
}

.line-height-5 {
  line-height: 1.75 !important;
}

.line-height-6 {
  line-height: 2;
}

.line-height-7 {
  line-height: 2.25;
}

.list-style-none {
  list-style: none;
}

.underline {
  text-decoration: underline;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-reset {
  list-style: none;
  padding-left: 0;
}

ul {
  line-height: 1.75;
  margin: 1em 0;
}
ul li {
  list-style-type: disc;
  margin-left: 1.1em;
  text-indent: 0.2em;
}
