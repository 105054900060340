html {
  font-size: 16px;
}
@media (max-width: 1024px) {
  html {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  html {
    font-size: 10px;
  }
}
/*

h1 - 54 - 3.60 rem
h2 - 45 - 3.00 rem
h3 - 37 - 2.46 rem
h4 - 31 - 2.06 rem
h5 - 18 - 1.20 rem
h6 - 15 - 1.00 rem

 */

h1,
.h1 {
  font-size: 3.5rem;
  line-height: 1.111;
}
.h1-5 {
  font-size: 3.42rem;
}
.h2 {
  font-size: 2.82rem;
}
.h3 {
  font-size: 2.25rem;
}
.h4 {
  font-size: 2rem;
}
.h5 {
  font-size: 1.75rem;
}
.body {
  font-size: 1.5rem;
}
.base {
  font-size: 1rem;
}
.h6 {
  font-size: 1.125rem;
}
.h7 {
  font-size: 0.875rem;
}
.h8 {
  font-size: 0.75rem;
}

@media (max-width: 767px) {
  h1,
  .h1,
  .title {
    font-size: 2.5rem;
    line-height: 1.111;
  }
  .h1-5 {
    font-size: 2rem;
  }
  /* .h2 {
  font-size: 2.82rem;
} */
  /* .h3 {
  font-size: 2.25rem;
} */
  /* .h4 {
  font-size: 2rem;
} */
  /* .h5 {
  font-size: 1.75rem;
} */
  .body {
    font-size: 1.25rem;
  }
  .body-m {
    font-size: 1rem;
  }
  /* .base {
  font-size: 1rem;
} */
  /* .h6 {
  font-size: 1.125rem;
} */
  /* .h7 {
  font-size: 0.875rem;
} */
  /* .h8 {
  font-size: 0.75rem;
} */
}

.letter-spacing06 {
  letter-spacing: 0.6px;
}
