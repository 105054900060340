.wrap {
  width: 100%;
  max-width: 76.4%;
	margin: 0 auto;
}

@media (min-width: 1280px) {
	.wrap {
		max-width: 1100px;
	}
}

@media (max-width: 767px) {
	.wrap {
		max-width: 86.2%;
	}
}

.relative {
	position: relative;
}

.inline {
	display: inline;
}

.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.overflow-hidden {
	overflow: hidden;
}

.overflow-scroll {
	overflow: scroll;
}

.overflow-auto {
	overflow: auto;
}

.overflow-visible {
	overflow: visible !important;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.left {
	float: left;
}

.right {
	float: right;
}

.h-80{
	height: 80%;
}

.w-20 {
	width: 20%;
}

.w-31 {
	width: 31%;
}

.w-40 {
	width: 40%;
}

.w-50 {
	width: 50%;
}

.mw-50 {
	max-width: 50%;
}

.w-60 {
	width: 60%;
}

.w-80 {
	width: 80%;
}

.w-100 {
	width: 100%;
}

.w-100i {
	width: 100% !important;
}

.h-100 {
	height: 100%;
}

.fit {
	max-width: 100%;
}

.v-align {
  vertical-align: middle;
}

.v-top {
  vertical-align: top;
}

.pointer {
	cursor: pointer;
}

.hover-redWhite:hover {
	background: #ff7a8a !important;
}

.hover-redWhite:hover path,
.hover-redWhite:hover polyline {
	stroke: white !important;
}

.hover-whiteRed:hover {
	background: white !important;
}

.hover-whiteRed:hover path,
.hover-whiteRed:hover polyline {
	stroke: #ff7a8a !important;
}

@media (min-width: 1024px) {
	.aspect-ratio-box {
		height: 100%;
	}
}

@media (min-width: 768px) {
	.aspect-ratio-box {
		background: white;
	}
	.aspect-ratio-box::before {
		content: "";
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
		padding-top: 100%;
	}
	.aspect-ratio-box::after {
		content: "";
		display: table;
		clear: both;
	}
}

.show-m {
	display: none;
}

@media (max-width: 767px) {
	.show-m:not(.grid-leave) {
		display: initial !important;
	}

	.grid-leave {
		display: grid;
	}
}

.hide-m {
	display: initial;
}

@media (max-width: 767px) {
	.wm-100 {
		width: 100%;
	}

	.wm-80 {
		width: 80%;
	}

	.wm-90 {
		width: 90%;
	}

	.hide-m {
		display: none !important;
	}
}

@media (max-width: 330px) {
	.grid-leave {
		grid-template-columns: 1fr !important;
	}
}
