.title {
  font-family: Yrsa, serif;
  font-weight: 600;
  font-style: bold;
  font-size: 4.5rem;
  margin-bottom: 1.5rem;
  line-height: 1.111;
  color: #002748;
}

.card {
  box-shadow: 0 24px 24px 0 rgba(0, 39, 72, 0.12);
  border-style: solid;
  border-width: 0.5px;
  border-image-source: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.4) 5%,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
  cursor: pointer;
  word-wrap: break-word;
  transform: translateY(0);
  transition: all 0.2s ease-out;
}

.card:hover {
  box-shadow: 0 24px 24px 0 rgba(0, 39, 72, 0.2);
  background: #ff7a8a;
  transform: translateY(-22px);
}

.card:hover *:not(.color-candy) {
  color: white !important;
}

@media (min-width: 768px) {
  .card.card-alt > div > img {
    width: 100%;
    height: inherit;
    margin-top: 0;
  }
}

.card-alt:hover {
  box-shadow: 0 24px 24px 0 rgba(0, 39, 72, 0.12);
  background: white;
  transform: translateY(0);
  cursor: initial;
}

.card-alt:hover *:not(.color-candy) {
  color: initial !important;
}

.card-alt:hover .color-wg {
  color: #999 !important;
}

.icon {
  padding: 1rem;
  background: #fcebf4;
  border-radius: 100%;
  vertical-align: middle;
  transition: all 0.2s ease-out;
}

.icon.bg-salmon {
}

.icon.bg-salmon:hover,
.icon.bg-salmon:focus,
.icon.bg-salmon:active {
  background: #f7bfdb;
}

.icon.bg-white {
}

.icon.bg-white:hover,
.icon.bg-white:focus,
.icon.bg-white:active {
  background: #f7bfdb;
}

.download {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}

.social-icon {
  display: inline-block;
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid white;
  border-radius: 100%;
  background-size: auto 30%;
  padding: 1rem;
  background-color: transparent;
  transition: 0.2s ease;
  text-indent: -9999999999px;
}

.social-icon:hover,
.social-icon:active,
.social-icon:focus {
  background-color: #fff;
}

.social-icon--fb {
  background-image: url(./../img/socials/fbPink.svg);
  background-image: url(./../img/socials/fb.svg);
}

.social-icon--fb:hover {
  background-image: url(./../img/socials/fbPink.svg);
}

.social-icon--twitter {
  background-image: url(./../img/socials/twitterPink.svg);
  background-image: url(./../img/socials/twitter.svg);
}

.social-icon--twitter:hover {
  background-image: url(./../img/socials/twitterPink.svg);
}

.social-icon--insta {
  background-image: url(./../img/socials/instaPink.svg);
  background-image: url(./../img/socials/insta.svg);
}

.social-icon--insta:hover {
  background-image: url(./../img/socials/instaPink.svg);
}

.social-icon--medium {
  background-image: url(./../img/socials/mediumPink.svg);
  background-image: url(./../img/socials/medium.svg);
}

.social-icon--medium:hover {
  background-image: url(./../img/socials/mediumPink.svg);
}
