/* Basscss Padding */

.p0  { padding: 0 }

.pt0 { padding-top: 0 }

.pr0 { padding-right: 0 }

.pb0 { padding-bottom: 0 }

.pl0 { padding-left: 0 }

.px0 { padding-left: 0; padding-right:  0 }

.py0 { padding-top: 0;  padding-bottom: 0 }

.p0-8  { padding: 0.8rem }

.p1  { padding: 1rem }

.pt1 { padding-top: 1rem }

.pr1 { padding-right: 1rem }

.pb1 { padding-bottom: 1rem }

.pl1 { padding-left: 1rem }

.py1 { padding-top: 1rem; padding-bottom: 1rem }

.py-1-25 { padding-top: 1.25rem; padding-bottom: 1.25rem }

.px1 { padding-left: 1rem; padding-right: 1rem }

.p2  { padding: 2rem }

.pt2 { padding-top: 2rem }

.pr2 { padding-right: 2rem }

.pb2 { padding-bottom: 2rem }

.pl2 { padding-left: 2rem }

.py2 { padding-top: 2rem; padding-bottom: 2rem }

.px2 { padding-left: 2rem; padding-right: 2rem }

.py2-25 { padding-top: 2.25rem; padding-bottom: 2.25rem }

.p3  { padding: 3rem }

.pt3 { padding-top: 3rem }

.pr3 { padding-right: 3rem }

.pb3 { padding-bottom: 3rem }

.pl3 { padding-left: 3rem }

.py3 { padding-top: 3rem; padding-bottom: 3rem }

.px3 { padding-left: 3rem; padding-right: 3rem }

.py3-5 { padding-top: 3.5rem; padding-bottom: 3.5rem }
.px3-5 { padding-left: 3.5rem; padding-right: 3.5rem }

.p4  { padding: 4rem }

.pt4 { padding-top: 4rem }

.pr4 { padding-right: 4rem }

.pb4 { padding-bottom: 4rem }

.pl4 { padding-left: 4rem }

.py4 { padding-top: 4rem; padding-bottom: 4rem }

.px4 { padding-left: 4rem; padding-right: 4rem }

.pb5 { padding-bottom: 5rem; }

.py5 { padding-top: 5rem; padding-bottom: 5rem }

.px5 { padding-left: 5rem; padding-right: 5rem }

.pt5 { padding-top: 5rem; }

.pt6 { padding-top: 6rem; }

.pt7 { padding-top: 7rem; }

.pb7 { padding-bottom: 7rem; }

.py7 { padding-top: 7rem; padding-bottom: 7rem; }

.pt9 { padding-top: 9rem; }

.pt10 { padding-top: 10rem; }
.pb10 { padding-bottom: 10rem; }

.pt13 { padding-top: 13rem; }

.py13 { padding-top: 13rem; padding-bottom: 13rem; }

.pt19 { padding-top: 19rem; }

@media(max-width:767px) {
  .pml0 {
    padding-left: 0;
  }
  .pmx0 { padding-left: 0rem; padding-right: 0rem }

  .pmy1 {
    padding-top: 1rem;padding-bottom: 1rem;
  }

  .pmb1-5 {
    padding-bottom: 1.5rem;
  }

  .pmx1 { padding-left: 1rem; padding-right: 1rem }

  .pmx2 { padding-left: 2rem; padding-right: 2rem }

  .pmy2 {
    padding-top: 2rem;padding-bottom: 2rem;
  }

  .pmy3 {
    padding-top: 3rem;padding-bottom: 3rem;
  }

  .pmt0 {
    padding-top: 0rem;
  }

  .pmt4 {
    padding-top: 4rem;
  }

  .pmb5 {
    padding-bottom: 5rem;
  }

  .pmt6 {
    padding-top: 6rem;
  }

  .pmt9 {
    padding-top: 9rem;
  }
}
